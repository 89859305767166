<template>
  <div class="container pt-4">
    <BaseHeader :title="$t('views.seller.my_account.text_1143')" class="mb-5">
      <div style="margin-top: -20px">
        <Locales />
      </div>
    </BaseHeader>
 
    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <!-- PESSOAL -->
      <b-tab
        id="user"
        :title="`${$t('views.seller.my_account.text_1567')} ${
          (
            user.documents.length > 1
              ? checkStatus(user.documents, 'APPROVED').length >= 2
              : checkStatus(user.documents, 'APPROVED').length
          )
            ? '✔️'
            : ''
        }`"
        :active="activeIndex === 0"
      >
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 class="my-3">{{ $t("views.seller.my_account.text_1568") }}</h5>
          <b-row>
            <b-col class="foto-user" cols="12" md="6">
              <b-form-group :label="$t('views.seller.my_account.text_1144')">
                <p
                  class="info-documento mb-2"
                  v-if="status_validation.status == 'INITIATED'"
                  data-anima="top"
                >
                  {{ $t("views.seller.my_account.text_1145") }}
                </p>

                <div class="top-status">
                  <div
                    class="aprovacao APPROVED btn-2 mt-2 mb-3"
                    v-if="status_validation.status == 'APPROVED'"
                  >
                    <!-- v-if="user.document_status == 'APPROVED'" -->
                    <img src="@/assets/img/icons/info-prod-aprovado.svg" />
                    {{ $t("views.seller.my_account.text_1146") }}
                  </div>

                  <div
                    class="aprovacao btn-2 mt-2 mb-3"
                    v-else-if="status_validation.status == 'REQUESTED'"
                  >
                    <img src="@/assets/img/icons/info-prod.svg" />
                    {{ $t("views.seller.my_account.text_1147") }}
                  </div>

                  <div
                    class="aprovacao DISAPPROVED btn-2 mt-2 mb-3"
                    v-else-if="status_validation.status == 'DISAPPROVED'"
                  >
                    <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                    {{ $t("views.seller.my_account.text_1148") }}
                  </div>
                </div>

                <!-- texto -->
                <p
                  class="info-documento"
                  v-if="status_validation.status == 'APPROVED'"
                >
                  {{ $t("views.seller.my_account.text_1149") }}<br />
                  {{ $t("views.seller.my_account.text_1150") }}
                  <a @click="openTab(2)" href="#" class="link-aprovado">{{
                    $t("views.seller.my_account.text_1151")
                  }}</a>
                </p>
                <!-- {{ checkStatus(user.documents, "DISAPPROVED") }} -->
                <p
                  class="info-documento"
                  v-else-if="status_validation.status == 'REQUESTED'"
                >
                  {{ $t("views.seller.my_account.text_1152") }} <br />
                  {{ $t("views.seller.my_account.text_1153") }}
                </p>

                <p
                  class="info-documento"
                  v-else-if="status_validation.status == 'DISAPPROVED'"
                >
                  {{ $t("views.seller.my_account.text_1154") }}<br />
                  {{ $t("views.seller.my_account.text_1155") }}
                  <b>{{ status_validation.observation }}</b>
                </p>
                <BaseButton
                  variant="secondary_outline"
                  class="mt-3 mb-2"
                  @click="openValidation"
                  v-if="
                    status_validation.status == 'INITIATED' ||
                    status_validation.status == 'DISAPPROVED'
                  "
                  >{{
                    status_validation.status == "DISAPPROVED"
                      ? $t("views.seller.my_account.text_1156")
                      : $t("views.seller.my_account.text_1157")
                  }}</BaseButton
                >
              </b-form-group>
            </b-col>

            <b-col class="foto-user" cols="12" md="6">
              <b-form-group label="Foto do perfil">
                <vue-upload-multiple-image
                  :multiple="false"
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :data-images="help_foto"
                  idUpload="userImgUpdate"
                  editUpload="userImgEdit"
                  dragText=""
                  :browseText="$t('views.seller.my_account.text_1158')"
                  :showPrimary="false"
                  :showEdit="false"
                ></vue-upload-multiple-image>
              </b-form-group>
            </b-col>
          </b-row>

          <h5 class="my-3">{{ $t("views.seller.my_account.text_1159") }}</h5>
          <form data-vv-scope="user">
            <b-row>
              <b-col md="4" sm="12">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1569')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    v-model="user.name"
                    type="text"
                    maxlength="40"
                    :placeholder="$t('views.seller.my_account.text_1160')"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.name')">
                    {{ $t("views.seller.my_account.text_1161") }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="false">
                    {{ $t("views.seller.my_account.text_1227") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1570')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    name="phone"
                    v-model="user.cellphone"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1571')"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.phone')">
                    {{ $t("views.seller.my_account.text_1572") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <!-- if (this.$store.getters.verifyEmail) { -->
                <b-form-group
                  :label="$t('views.seller.my_account.text_1573')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="user.email"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1574')"
                    v-validate="'required|email'"
                    :disabled="$store.getters.verifyEmail"
                    :readonly="$store.getters.verifyEmail"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.email')">
                    {{ $t("views.seller.my_account.text_1162") }}
                  </b-form-invalid-feedback>
                  <p
                    v-if="$store.getters.verifyEmail"
                    class="info-documento mt-2"
                  >
                    {{ $t("views.seller.my_account.text_1163") }}
                  </p>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1575')"
                  label-for="cpf"
                  id="campo-cpf"
                >
                  <b-form-input
                    id="cpf"
                    name="cpf"
                    v-model="user.cpf_cnpj"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1576')"
                    v-validate="'required|cpf_cnpj'"
                    v-mask="['###.###.###-##']"
                    :disabled="
                      status_validation.status == 'APPROVED' &&
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    "
                    :readonly="
                      status_validation.status == 'APPROVED' &&
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.cpf')">
                    {{ $t("views.seller.my_account.text_1164") }}
                  </b-form-invalid-feedback>

                  <b-tooltip
                    v-if="
                      status_validation.status == 'APPROVED' && user.cpf_cnpj
                    "
                    target="campo-cpf"
                    :title="$t('views.seller.my_account.text_1165')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1577')"
                  label-for="rg"
                  id="campo-rg"
                >
                  <b-form-input
                    id="rg"
                    name="rg"
                    v-model="user.rg"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1166')"
                    v-validate="'required'"
                    v-mask="['#############']"
                    :disabled="
                      status_validation.status == 'APPROVED' && dados_user.rg
                        ? true
                        : false
                    "
                    :readonly="
                      status_validation.status == 'APPROVED' && dados_user.rg
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.rg')">
                    {{ $t("views.seller.my_account.text_1167") }}
                  </b-form-invalid-feedback>

                  <b-tooltip
                    v-if="status_validation.status == 'APPROVED' && user.rg"
                    target="campo-rg"
                    :title="$t('views.seller.my_account.text_1165')"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h5 class="my-3">{{ $t("views.seller.my_account.text_1168") }}</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1578')"
                  label-for="cep"
                >
                  <b-form-input
                    id="cep"
                    v-model="address_user.zip_code"
                    :placeholder="$t('views.seller.my_account.text_1169')"
                    v-mask="['#####-###']"
                    type="text"
                    @keyup="preencherCep('usuario')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1170')"
                  label-for="rua"
                >
                  <b-form-input
                    id="rua"
                    v-model="address_user.street"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1170')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1579')"
                  label-for="num"
                >
                  <b-form-input
                    id="num"
                    v-model="address_user.number"
                    type="text"
                    v-mask="['########']"
                    :placeholder="$t('views.seller.my_account.text_1171')"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1580')"
                  label-for="bairro"
                >
                  <b-form-input
                    id="bairro"
                    v-model="address_user.neighborhood"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1172')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1581')"
                  label-for="cidade"
                >
                  <b-form-input
                    id="cidade"
                    v-model="address_user.city"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1582')"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1583')"
                  label-for="estado"
                >
                  <b-form-input
                    id="estado"
                    v-model="address_user.state"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1584')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <h5 class="my-3">{{ $t("views.seller.my_account.text_1585") }}</h5>
            <div>
              <BaseButton variant="black" @click="openResetSenha">{{
                $t("views.seller.my_account.text_1173")
              }}</BaseButton>
            </div>

            <b-row>
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton variant="primary" @click="onSubmit('user')">{{
                  $t("views.seller.my_account.text_1174")
                }}</BaseButton>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <!-- EMPRESARIAL -->
      <b-tab
        id="company"
        :title="`${$t('views.seller.my_account.text_1175')} ${
          checkStatus(company.documents, 'APPROVED').length ? '✔️' : ''
        }`"
        :active="activeIndex === 1"
      >
        <b-container fluid class="mt-1 wrapper container-user" v-if="!loading">
          <h5 class="my-3">{{ $t("views.seller.my_account.text_1176") }}</h5>
          <form data-vv-scope="company">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1586')"
                  label-for="name_company"
                >
                  <b-form-input
                    id="name_company"
                    name="name_company"
                    v-model="company.name"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1177')"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.name_company')"
                  >
                    {{ $t("views.seller.my_account.text_1178") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1179')"
                  label-for="fantasy_name"
                >
                  <b-form-input
                    id="fantasy_name"
                    name="fantasy_name"
                    maxlength="40"
                    v-model="company.fantasy_name"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1180')"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false">
                    {{ $t("views.seller.my_account.text_1227") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1181')"
                  label-for="cnpj"
                  id="campo-cnpj"
                >
                  <b-form-input
                    id="cnpj"
                    name="cnpj"
                    v-model="company.cnpj"
                    :placeholder="$t('views.seller.my_account.text_1182')"
                    v-mask="['##.###.###/####-##']"
                    v-validate="'required'"
                    :disabled="
                      checkStatus(company.documents, 'APPROVED').length
                        ? true
                        : false
                    "
                    :readonly="
                      checkStatus(company.documents, 'APPROVED').length
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('company.cnpj')">
                    {{ $t("views.seller.my_account.text_1587") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-tooltip
                  v-if="checkStatus(company.documents, 'APPROVED').length"
                  target="campo-cnpj"
                  :title="$t('views.seller.my_account.text_1183')"
                />
              </b-col>

              <b-col cols="12" md="6" class="mt-4">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1184')"
                  label-for="contract"
                  v-if="!company.documents.length"
                  data-anima="top"
                >
                  <b-form-file
                    id="contract"
                    name="contract"
                    plain
                    accept=".pdf"
                    v-model="contract_user"
                    v-validate="'required'"
                    @change="changeContract = true"
                  ></b-form-file>

                  <b-form-invalid-feedback
                    :state="!errors.has('company.contract')"
                  >
                    {{ $t("views.seller.my_account.text_1185") }}
                  </b-form-invalid-feedback>
                  <p class="info-documento">
                    {{ $t("views.seller.my_account.text_1186") }}
                  </p>
                </b-form-group>

                <b-form-group
                  :label="$t('views.seller.my_account.text_1184')"
                  label-for="contract"
                  v-show="company.documents.length"
                >
                  <div class="top-status">

                    <div
                      class="aprovacao APPROVED btn-2 mt-3 mb-3"
                      v-if="checkStatus(mostRecentSocialContract(company.documents), 'APPROVED').length"
                    >
                      <img src="@/assets/img/icons/info-prod-aprovado.svg" />
                      {{ $t("views.seller.my_account.text_1187") }}
                    </div>

                    <div
                      class="aprovacao btn-2 mt-3 mb-3"
                      v-else-if="
                        checkStatus(mostRecentSocialContract(company.documents), 'REQUESTED').length
                      "
                    >
                      <img src="@/assets/img/icons/info-prod.svg" />
                      {{ $t("views.seller.my_account.text_1188") }}
                    </div>

                    <div
                      class="aprovacao DISAPPROVED btn-2 mt-3 mb-3"
                      v-else-if="
                        checkStatus(mostRecentSocialContract(company.documents), 'DISAPPROVED').length
                      "
                    >
                      <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                      {{ $t("views.seller.my_account.text_1189") }}
                    </div>

                    <!-- ver arquivo -->
                    <a
                      v-if="
                        checkStatus(mostRecentSocialContract(company.documents), 'DISAPPROVED').length &&
                        !checkStatus(mostRecentSocialContract(company.documents), 'APPROVED').length
                      "
                      href="#"
                      class="link-aprovado ver-arquivo"
                      @click.prevent="openAndamentoDocs(company.last_document)"
                      >{{ $t("views.seller.my_account.text_1588") }}</a
                    >
                    <a
                      v-if="
                        checkStatus(mostRecentSocialContract(company.documents), 'DISAPPROVED').length&&
                        !checkStatus(mostRecentSocialContract(company.documents), 'APPROVED').length
                      "
                      href="#"
                      class="link-aprovado ver-arquivo"
                      @click.prevent="company.documents = []"
                      >{{ $t("views.seller.my_account.text_1589") }}</a
                    >
                  </div>

                  <!-- texto -->
                  <p
                    class="info-documento"
                    v-if="checkStatus(mostRecentSocialContract(company.documents), 'APPROVED').length"
                  >
                    {{ $t("views.seller.my_account.text_1190") }}<br />
                    {{ $t("views.seller.my_account.text_1191") }}
                    <a @click="openTab(2)" href="#" class="link-aprovado">{{
                      $t("views.seller.my_account.text_1151")
                    }}</a>
                  </p>
                  <p
                    class="info-documento"
                    v-else-if="
                      checkStatus(mostRecentSocialContract(company.documents), 'REQUESTED').length
                    "
                  >
                    {{ $t("views.seller.my_account.text_1192") }} <br />
                    {{ $t("views.seller.my_account.text_1193") }}
                  </p>
                  <p
                    class="info-documento"
                    v-else-if="
                      checkStatus(mostRecentSocialContract(company.documents), 'DISAPPROVED').length
                    "
                  >
                    {{ $t("views.seller.my_account.text_1194") }}<br />
                    {{ $t("views.seller.my_account.text_1155") }}
                    <b>{{
                      checkStatus(mostRecentSocialContract(company.documents), "DISAPPROVED")[
                        checkStatus(mostRecentSocialContract(company.documents), "DISAPPROVED").length - 1
                      ].pending_motive
                    }}</b>
                  </p>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="email-company">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1570')"
                  label-for="phone_company"
                >
                  <b-form-input
                    id="phone_company"
                    name="phone_company"
                    v-model="company.telephone"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1590')"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.phone_company')"
                  >
                    {{ $t("views.seller.my_account.text_1591") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- if (this.$store.getters.verifyEmail) { -->
              </b-col>
            </b-row>

            <b-row> </b-row>

            <h5 class="my-3">{{ $t("views.seller.my_account.text_1592") }}</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1573')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="company.email"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1593')"
                    @focusout="validateEmail()"
                  ></b-form-input>
                 
                  <b-form-invalid-feedback
                    :state="!emailError"
                  >
                    {{ $t("views.seller.my_account.text_1229") }}
                  </b-form-invalid-feedback>


                  <p v-if="company.email" class="info-documento mt-2">
                    {{ $t("views.seller.my_account.text_1195") }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1594')"
                  label-for="support_telephone"
                >
                  <b-form-input
                    id="support_telephone"
                    name="support_telephone"
                    v-model="company.support_telephone"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1594')"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.support_telephone')"
                  >
                    {{ $t("views.seller.my_account.text_1595") }}
                  </b-form-invalid-feedback>
                  <p
                    v-if="company.support_telephone"
                    class="info-documento mt-2"
                  >
                    {{ $t("views.seller.my_account.text_1195") }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row> </b-row>

            <h5 class="my-3">{{ $t("views.seller.my_account.text_1597") }}</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1598')"
                  label-for="cep_company"
                >
                  <b-form-input
                    id="cep_company"
                    v-model="company.zip_code"
                    :placeholder="$t('views.seller.my_account.text_1599')"
                    v-mask="['#####-###']"
                    type="text"
                    @keyup="preencherCep('empresa')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1170')"
                  label-for="rua_company"
                >
                  <b-form-input
                    id="rua_company"
                    v-model="company.street"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1600')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1579')"
                  label-for="num_company"
                >
                  <b-form-input
                    id="num_company"
                    v-model="company.number"
                    type="text"
                    v-mask="['########']"
                    :placeholder="$t('views.seller.my_account.text_1601')"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1580')"
                  label-for="bairro_company"
                >
                  <b-form-input
                    id="bairro_company"
                    v-model="company.neighborhood"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1602')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1581')"
                  label-for="cidade_company"
                >
                  <b-form-input
                    id="cidade_company"
                    v-model="company.city"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1603')"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('views.seller.my_account.text_1583')"
                  label-for="estado_company"
                >
                  <b-form-input
                    id="estado_company"
                    v-model="company.state"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1604')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton variant="primary" @click="onSubmit('company')" :disabled="emailError">{{
                  $t("views.seller.my_account.text_1174")
                }}</BaseButton>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <!-- CONTAS BANCÁRIAS -->
      <b-tab 
        id="bankAccount" 
        :title="$t('views.seller.my_account.text_1605')"
        :active="activeIndex === 2"
      >
        <b-container
          fluid
          class="mt-1 wrapper container-user bank-account-container"
          v-show="!loading"
        >
          <!-- SE A CONTA NÃO ESTIVER APROVADA -->
          <template v-if="status_validation.status !== 'APPROVED'">
            <b-row>
              <b-col class="foto-user" cols="12" md="9">
                <b-form-group :label="$t('views.seller.my_account.text_1144')">
                  <p
                    class="info-documento mb-2"
                    v-if="status_validation.status == 'INITIATED'"
                    data-anima="top"
                  >
                    {{ $t("views.seller.my_account.text_1228") }}
                  </p>

                  <div class="top-status">
                    <div
                      class="aprovacao APPROVED btn-2 mt-2 mb-3"
                      v-if="status_validation.status == 'APPROVED'"
                    >
                      <!-- v-if="user.document_status == 'APPROVED'" -->
                      <img src="@/assets/img/icons/info-prod-aprovado.svg" />
                      {{ $t("views.seller.my_account.text_1146") }}
                    </div>

                    <div
                      class="aprovacao btn-2 mt-2 mb-3"
                      v-else-if="status_validation.status == 'REQUESTED'"
                    >
                      <img src="@/assets/img/icons/info-prod.svg" />
                      {{ $t("views.seller.my_account.text_1147") }}
                    </div>

                    <div
                      class="aprovacao DISAPPROVED btn-2 mt-2 mb-3"
                      v-else-if="status_validation.status == 'DISAPPROVED'"
                    >
                      <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                      {{ $t("views.seller.my_account.text_1148") }}
                    </div>
                  </div>

                  <!-- texto -->
                  <p
                    class="info-documento"
                    v-if="status_validation.status == 'APPROVED'"
                  >
                    {{ $t("views.seller.my_account.text_1149") }}<br />
                    {{ $t("views.seller.my_account.text_1150") }}
                    <a @click="openTab(2)" href="#" class="link-aprovado">{{
                      $t("views.seller.my_account.text_1151")
                    }}</a>
                  </p>
                  <!-- {{ checkStatus(user.documents, "DISAPPROVED") }} -->
                  <p
                    class="info-documento"
                    v-else-if="status_validation.status == 'REQUESTED'"
                  >
                    {{ $t("views.seller.my_account.text_1152") }} <br />
                    {{ $t("views.seller.my_account.text_1153") }}
                  </p>

                  <p
                    class="info-documento"
                    v-else-if="status_validation.status == 'DISAPPROVED'"
                  >
                    {{ $t("views.seller.my_account.text_1154") }}<br />
                    {{ $t("views.seller.my_account.text_1155") }}
                    <b>{{ status_validation.observation }}</b>
                  </p>
                  <BaseButton
                    variant="secondary_outline"
                    class="mt-3 mb-2"
                    @click="openValidation"
                    v-if="
                      status_validation.status == 'INITIATED' ||
                      status_validation.status == 'DISAPPROVED'
                    "
                    >{{
                      status_validation.status == "DISAPPROVED"
                        ? $t("views.seller.my_account.text_1156")
                        : $t("views.seller.my_account.text_1157")
                    }}</BaseButton
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <!-- SE A CONTA JÁ ESTIVER APROVADA -->
          <template v-else>
            <BankAccount :accountApproved="status_validation.status == 'APPROVED'" />
          </template>
        </b-container>
      </b-tab>
      <div
        key="loading"
        v-if="loading"
        class="py-5 my-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"> </b-spinner>
        <p class="ml-4 info-documento">
          {{ $t("views.seller.my_account.text_1596") }}
        </p>
      </div>
    </b-tabs>
    <ResetPassword :user_id="user.id" />
    <AndamentoDocs :docs="andamento" />
    <ModalValidation @close="initDados" />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import BankAccount from "@/components/Seller/MyAccount/bankAccount";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import ResetPassword from "@/components/Seller/MyAccount/modals/reset_password.vue";
import AndamentoDocs from "@/components/Seller/MyAccount/modals/AndamentoDocs.vue";

import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

import ValidationService from "@/services/resources/ValidationService";
const serviceValidation = ValidationService.build();

import Locales from "@/components/Locales";

export default {
  data() {
    return {
      activeTab: null,
      dados_user: {},
      loading: false,
      submit: false,
      status_validation: {
        status: "",
        observation: "",
      },
      foto_user: [],
      help_foto: [],
      document_user: [],
      contract_user: null,
      changeContract: false,

      troca_senha: false,
      andamento: [],
      user: {
        id: -1,
        name: "",
        cellphone: "",
        email: "",
        cpf_cnpj: "",
        rg: "",
        image: [],
        documents: [
          {
            status: "REQUESTED",
          },
        ],
        old_password: "",
        new_password: "",
      },
      address_user: {
        zip_code: "00000-000",
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
      },

      company: {
        last_document: {},
        documents: [
          {
            status: "REQUESTED",
          },
        ],
        name: "",
        fantasy_name: "",
        telephone: "",
        support_telephone: "",
        email: "",
        cnpj: "",
        zip_code: "00000-000",
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
      }, 
      emailError: false
    };
  },
  components: {
    BankAccount,
    VueUploadMultipleImage,
    ResetPassword,
    AndamentoDocs,
    Locales,
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    activeIndex() {
      return this.activeTab;
    }
  },
  methods: {
    mostRecentSocialContract(docs) {
      if (docs.length === 0) {
        return [];
      }

      let documentMostRecent = docs[0];

      for (let i = 1; i < docs.length; i++) {
        const currentDocument = docs[i];
        const currentDate = new Date(currentDocument.updated_at);
        const dateMostRecent = new Date(documentMostRecent.updated_at);

        if (currentDate > dateMostRecent) {
          documentMostRecent = currentDocument;
        }
      }

      return [documentMostRecent];
    },
    openTab(number) {
      this.activeTab = number;
    },
    openValidation() {
      this.$bvModal.show("identity-validation");
    },
    acessarContaCorrente() {
      this.$router.push("extrato/?withdraw=true");
    },
    openAndamentoDocs(docs) {
      this.andamento = [docs];
      this.$bvModal.show("andamento_docs");
    },
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    openResetSenha() {
      this.$bvModal.show("reset_password");
    },

    // foto usuario
    uploadImageSuccess(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t("views.seller.my_account.text_1606"));
      if (r == true) {
        done();
        this.foto_user.splice(index, 1);
        this.help_foto.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },

    // documento usuario
    uploadDocumentSuccess(formData, index, fileList) {
      this.document_user = fileList;
    },
    beforeRemoveDocument(index, done, fileList) {
      var r = confirm(this.$t("views.seller.my_account.text_1196"));
      if (r == true) {
        done();
        this.document_user.splice(index, 1);
      }
    },
    editDocument(formData, index, fileList) {
      this.document_user = fileList;
    },

    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    onSubmit(tipo) {
      if(this.company.email && this.company.email.trim() !== ''){
        this.validateEmail();
      }
      
      if(this.emailError){
        return;
      }
      this.submit = true;
      this.loading = true;
      

      let validaItem = true;
      if (tipo == "user" && !this.user.documents.length) {
        validaItem = this.document_user ? true : false;
      } else if (tipo == "company" && !this.company.documents.length) {
        validaItem = this.contract_user ? true : false;
      }

      this.$validator.validateAll(tipo).then(async (result) => {
        if (result && validaItem) {
          let data = {
            id: this.user.id,
            company: {},
          };
          if (tipo == "user") {
            data = this.user;
            data.address = this.address_user;
            data.image = this.foto_user[0];
            data.documents = this.document_user;

            delete data.company;
          } else if (tipo == "company") {
            data.company = this.company;
            if (this.changeContract) {
              data.company.documents = [this.contract_user];
            } else {
              data.company.documents = [];
            }
          }

          data._method = "PUT";
          data = this.jsonToFormData(data);

          serviceUser
            .create(data, this.user.id)
            .then(() => {
              // resetando coockie
              this.initDados();

              if (tipo == "user") {
                this.user.documents = [{ status: "REQUESTED" }];
              } else if (tipo == "company") {
                this.company.documents = [{ status: "REQUESTED" }];
              }

              this.$bvToast.toast(
                this.$t("views.seller.my_account.text_1197"),
                {
                  title: this.$t("views.seller.my_account.text_1198"),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
            })
            .catch((error) => {
              if (tipo == "company") {
                this.company.contract = null;
              }
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
            });
        } else {
          this.loading = false;
          this.$bvToast.toast(this.$t("views.seller.my_account.text_1199"), {
            title: this.$t("views.seller.my_account.text_1198"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },

    preencherCep(tipo) {
      let cep = "";
      if (tipo == "empresa") {
        cep = this.company.zip_code.replace(/\D/g, "");
      } else if (tipo == "usuario") {
        cep = this.address_user.zip_code.replace(/\D/g, "");
      }
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => response.json())
          .then((response) => {
            if (tipo == "empresa") {
              this.company.street = response.logradouro;
              this.company.neighborhood = response.bairro;
              this.company.city = response.localidade;
              this.company.state = response.uf;
              if (document.querySelector("#num_company")) {
                document.querySelector("#num_company").focus();
              }
            } else {
              this.address_user.street = response.logradouro;
              this.address_user.neighborhood = response.bairro;
              this.address_user.city = response.localidade;
              this.address_user.state = response.uf;
              if (document.querySelector("#num")) {
                document.querySelector("#num").focus();
              }
            }
          });
      }
    },

    getStatusValidation(id) {
      let data = {
        id: id,
      };
      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setDados() {
      this.getStatusValidation(this.dados_user.id);
      this.user = {
        id: this.dados_user.id,
        name: this.dados_user.name,
        cellphone: this.dados_user.cellphone,
        rg: this.dados_user.rg,
        email: this.dados_user.email,
        cpf_cnpj: this.dados_user.cpf_cnpj,
        image: this.dados_user.image,
        documents: this.dados_user.documents || [],
      };

      this.document_user = this.user.documents;

      if (this.user.image) {
        this.help_foto = [
          {
            path: this.user.image,
          },
        ];
      }

      this.address_user = Object.assign({}, this.dados_user.address);

      let dados_company = Object.assign({}, this.dados_user.company);
      this.company = {
        contract: dados_company.contract,
        name: dados_company.name,
        fantasy_name: dados_company.fantasy_name,
        telephone: dados_company.telephone,
        support_telephone: dados_company.support_telephone,
        cnpj: dados_company.cnpj,
        email: dados_company.email,
        zip_code: dados_company.zip_code,
        street: dados_company.street,
        number: dados_company.number,
        neighborhood: dados_company.neighborhood,
        city: dados_company.city,
        state: dados_company.state,
        documents: dados_company.documents || [],
        last_document: dados_company.last_document || {},
      };

      this.loading = false;
    },

    initDados() {
      this.loading = true;

      this.$store
        .dispatch("userRequest")
        .then((response) => {
          this.dados_user = Object.assign({}, response);
        })
        .then(() => {
          this.setDados();
        })
        .catch((err) => {
          console.log(err);
          this.dados_user = Object.assign({}, this.$store.state.user.user);
          this.setDados();
        });
    },

    validateEmail() {

      const email = this.company.email;
      let errorMessage = ''; 
      
      if (email === '') {
        this.emailError = false;
        return;
      }

      // Expressão regular para validar email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      const commonDomains = [
          'gmail.com', 'hotmail.com', 'yahoo.com',
          'gmail.com.br', 'hotmail.com.br', 'icloud.com',
          'outlook.com', 'yahoo.com.br', 'uol.com.br',
          'live.com', 'terra.com.br', 'bol.com.br',
          'cogna.com.br', 'outlook.com.br', 'kroton.com.br',
          'parceiro-kroton.com.br', 'msn.com', 'usp.br',
          'escola.pr.gov.br', 'anhanguera.com', 'unesp.br',
          'stoodi.com.br', 'mepoupe.com', 'educacao.mg.gov.br',
          'voomp.com.br', 'maildrop.cc'
      ];

      if (!emailRegex.test(email)) {
          errorMessage = this.$t('client.invalid_email');
          this.emailError = true;
      } else {        
        const domainPart = email.split('@')[1];
        this.emailError = false;
        if (!commonDomains.includes(domainPart)) {
          const similarDomain = commonDomains.some(domain => this.isSimilarDomain(domain, domainPart));
          if (similarDomain) {
              errorMessage = this.$t('client.invalid_domain');
              this.emailError = true;
          } else {
            this.emailError = false;
          }
        }
      }
      
    },
    isSimilarDomain(domain1, domain2) {
        const levenshteinDistance = (a, b) => {
            const matrix = [];
            let i;
            for (i = 0; i <= b.length; i++) { matrix[i] = [i]; }
            let j;
            for (j = 0; j <= a.length; j++) { matrix[0][j] = j; }
            for (i = 1; i <= b.length; i++) {
                for (j = 1; j <= a.length; j++) {
                    if (b.charAt(i - 1) === a.charAt(j - 1)) {
                        matrix[i][j] = matrix[i - 1][j - 1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
                    }
                }
            }
            return matrix[b.length][a.length];
        };

        return levenshteinDistance(domain1, domain2) <= 2;
    },
  },
  mounted() {
    this.initDados();
    if(this.company.email && this.company.email.trim() !== ''){
      this.validateEmail();
    }
    if(this.$route.query.tab){
      this.openTab(parseInt(this.$route.query.tab))
    }
  },
};
</script>

<style scoped>
.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: #002363;
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
  .bank-account-container {
    padding: 0px;
    border: 0px !important;
    margin-bottom: 20px;
  }
}

.info-documento {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 33, 99, 0.1);
  color: #002363;
}
.link-aprovado {
  font-weight: 600;
  color: #2a63ab;
}
.ver-arquivo {
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}

.top-status {
  display: flex;
  align-items: center;
  gap: 30px;
}

.email-company {
  margin-top: -75px;
}
@media screen and (max-width: 768px) {
  .top-status {
    display: block;
  }
  .ver-arquivo {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .email-company {
    margin-top: initial;
  }
  .email-error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }
}
</style>
