<template>
  <b-container class="my-account">
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <b-row id="backAccountTop">
      <b-col cols="12">
        <!-- Lista todas as contas bancárias -->
        <bank-account-preview-list
          v-if="bankAccounts.length > 0"
          :bankAccounts="bankAccounts"
          @update-form="updateBankAccounts"
          :accountApproved="accountApproved"
          :loading="loading"
        />

        <!-- Cadastrar as contas bancárias -->
        <b-form novalidate v-if="checkForm">
          <b-row class="register-account">
            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <!-- Contas Bancárias -->
                  <h5 class="register-account__title">
                    {{ $t("seller.my_account.bank_account.add_bank_account") }}
                  </h5>
                </b-col>
              </b-row>

              <b-row>
                <!-- Pesquisar banco -->
                <b-col cols="12" md="4" v-b-modal.bank-account-bank-list>
                  <div class="form-group">
                    <label for="email" class="d-block">
                      {{
                        $t("seller.my_account.bank_account.bank_search")
                      }}</label
                    >
                    <div class="form-control form-control-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1990_2545)">
                          <path
                            d="M15.5329 15.8416L11.9793 12.2879M13.8991 7.6722C13.8991 11.2816 10.973 14.2077 7.3636 14.2077C3.75416 14.2077 0.828125 11.2816 0.828125 7.6722C0.828125 4.06275 3.75416 1.13672 7.3636 1.13672C10.973 1.13672 13.8991 4.06275 13.8991 7.6722Z"
                            stroke="#81858E"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1990_2545">
                            <rect width="17" height="17" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{{
                        $t("seller.my_account.bank_account.find_your_bank")
                      }}</span>
                    </div>
                  </div>
                </b-col>

                <!-- Código do banco -->
                <b-col cols="6" md="2">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.bank_code')"
                    label-for="bank_number"
                  >
                    <b-form-input
                      id="bank_number"
                      name="bank_number"
                      v-model="bank_number"
                      v-validate="'required'"
                      autocomplete="off"
                      placeholder="- - -"
                      type="text"
                      :disabled="disabledBankFilds"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="!errors.has('bank_number')"
                    >
                      {{ $t("seller.my_account.bank_account.error_bank_code") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Nome do banco -->
                <b-col cols="6" md="2">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.bank_name')"
                    label-for="bank_name"
                  >
                    <b-form-input
                      id="bank_name"
                      name="bank_name"
                      v-model="bank_name"
                      v-validate="'required'"
                      autocomplete="off"
                      placeholder="- - -"
                      type="text"
                      :disabled="disabledBankFilds"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('bank_name')">
                      {{ $t("seller.my_account.bank_account.error_bank_name") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Tipo de conta -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.type_account')"
                    label-for=""
                  >
                    <b-form-select
                      id="type"
                      name="type"
                      :value="type"
                      v-model="type"
                      v-validate="'required'"
                    >
                      <b-form-select-option
                        v-for="account in accountTypes"
                        :key="account.value"
                        :value="account.value"
                      >
                        {{
                          $t(
                            "seller.my_account.bank_account.account_types." +
                              account.value
                          )
                        }}
                      </b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback :state="!errors.has('type')">
                      {{
                        $t("seller.my_account.bank_account.error_type_account")
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Modalidade -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.modality')"
                    label-for="modality"
                  >
                    <b-form-select
                      id="modality"
                      name="modality"
                      v-model="modality"
                      v-validate="'required'"
                    >
                      <b-form-select-option
                        v-for="item in accountModality"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{
                          $t(
                            "seller.my_account.bank_account.modality_types." +
                              item.value
                          )
                        }}
                      </b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback :state="!errors.has('modality')">
                      {{ $t("seller.my_account.bank_account.error_modality") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Agência -->
                <b-col cols="7" md="4">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.agency')"
                    label-for="agency"
                  >
                    <b-form-input
                      id="agency"
                      name="agency"
                      v-model="agency"
                      v-validate="'required'"
                      v-mask="'#########'"
                      autocomplete="off"
                      placeholder="0000"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('agency')">
                      {{ $t("seller.my_account.bank_account.error_agency") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- DV (Opcional) -->
                <b-col cols="5" md="2">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.dv_optional')"
                    label-for="agency_dv"
                  >
                    <b-form-input
                      id="agency_dv"
                      name="agency_dv"
                      v-mask="'#'"
                      v-model="agency_dv"
                      placeholder="0"
                      autocomplete="off"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Conta -->
                <b-col cols="7" md="4">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.account')"
                    label-for="account"
                  >
                    <b-form-input
                      id="account"
                      name="account"
                      v-model="account"
                      v-validate="'required'"
                      v-mask="'############'"
                      autocomplete="off"
                      placeholder="00000"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('account')">
                      {{ $t("seller.my_account.bank_account.error_account") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- DV -->
                <b-col cols="5" md="2">
                  <b-form-group
                    :label="$t('seller.my_account.bank_account.dv')"
                    label-for="account_dv"
                  >
                    <b-form-input
                      id="account_dv"
                      name="account_dv"
                      type="text"
                      v-model="account_dv"
                      placeholder="0"
                      autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('account_dv')">
                      {{ $t("seller.my_account.bank_account.error_dv") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="interAccess">
            <b-col cols="12" md="12" class="mb-4">
              <b-form-group label="Selecione a(s) moeda(s):">
                <multiselect
                  v-model="selected_currencies"
                  :options="coins"
                  :value="selected_currencies"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder=""
                  label="name"
                  track-by="id"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  name="selected_currencies"
                  v-validate="'required'"
                >
                  <span slot="noOptions">Nenhuma moeda encontrada.</span>
                  <span slot="noResult">Nenhuma moeda encontrada.</span>
                </multiselect>
                <b-form-invalid-feedback
                  :state="!errors.has('selected_currencies')"
                >
                  Selecione uma ou mais moedas.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Upload de comprovante de conta bancária -->
          <div class="file-upload">
            <b-col cols="12" class="file-upload__card">
              <b-row>
                <b-col cols="12">
                  <h3 class="file-upload__title">
                    {{
                      $t(
                        "seller.my_account.bank_account.bank_account_verification"
                      )
                    }}
                  </h3>
                  <p class="file-upload__description">
                    {{
                      $t(
                        "seller.my_account.bank_account.bank_account_verification_description"
                      )
                    }}
                  </p>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label=""
                    label-for="bank-statement"
                    data-anima="top"
                  >
                    <b-form-file
                      id="bank-statement"
                      name="bank-statement"
                      class="form-input-file"
                      plain
                      v-model="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      v-validate 
                      data-vv-rules="fileExtensions:jpg,jpeg,png,pdf|required"
                    ></b-form-file>

                    <b-form-invalid-feedback
                      :state="!errors.has('bank-statement')"
                    >
                      {{
                        $t("seller.my_account.bank_account.error_upload_file")
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <p class="file-upload__accepted-formats">
                    {{ $t("seller.my_account.bank_account.accepted_formats") }}
                  </p>
                </b-col>

                <b-col cols="12">
                  <p class="file-upload__alert">
                    {{ $t("seller.my_account.bank_account.info_security") }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </div>

          <!-- Adicionar conta -->
          <b-row class="action-buttons">
            <b-col cols="12">
              <div class="action-buttons__button">
                <BaseButton
                  variant="primary"
                  @click="submitForm"
                  :disabled="
                    loading ||
                    (status_validation.status != 'APPROVED' &&
                      (user.company
                        ? !checkStatus(user.company.documents, 'APPROVED')
                            .length
                        : true))
                  "
                >
                  {{
                    !update
                      ? $t("seller.statements.modals.bank_account.text_813")
                      : $t("seller.statements.modals.bank_account.text_814")
                  }}
                </BaseButton>
              </div>
            </b-col>
          </b-row>
        </b-form>

        <!-- Selecionar banco -->
        <modal-bank-account-bank-list @select="selectBank" />

        <modal-validation @close="getStatusValidation" />
      </b-col>
    </b-row>
  </b-container>
</template>

<!-- Style -->
<style lang="scss">
.my-account {
  .register-account {
    &__title {
      @include title-style;
    }
  }

  .file-upload {
    &__card {
      border-radius: var(--default-card-radius);
      background: var(--color-neutral-gray-300);
      padding: var(--default-card-padding);
      margin-top: var(--spacing-24);
      margin-bottom: var(--spacing-24);
    }

    &__title {
      @include title-style;
      margin-top: 0;
    }

    &__description {
      color: var(--color-neutral-gray-900);
      font-size: var(--font-base);
      font-style: normal;
      font-weight: 400;
      @include form-style;
    }

    &__accepted-formats {
      font-size: var(--font-extra-small);
    }

    &__alert {
      font-size: var(--font-small);
    }
  }

  .action-buttons {
    &__button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

<!-- Javascript -->
<script>
import { Validator } from "vee-validate";

import BankAccountPreviewList from "./bankAccountPreviewList.vue";
import BaseModal from "../../BaseModal.vue";
import Multiselect from "vue-multiselect";
import BankAccountService from "@/services/resources/BankAccountService";
import ModalBankAccountRemove from "./bankAccountRemove";
import ModalBankAccountBankList from "./bankAccountBankList";
import ValidationService from "@/services/resources/ValidationService";
import CurrencyCurrenciesService from "@/services/resources/CurrencyCurrenciesService";

const serviceCurrencyCurrencies = CurrencyCurrenciesService.build();
const serviceValidation = ValidationService.build();
const bankAccountService = BankAccountService.build();

Validator.extend("fileExtensions", {

  validate: (value, extensions) => {

    if (!value) {
      return true;
    }
    
    if (!extensions || !extensions.length) {
      return false;
    }
    
    const fileExtension = value.name.split(".").pop().toLowerCase();
    
    return extensions.includes(fileExtension);
  }
});

export default {
  components: {
    BankAccountPreviewList,
    BaseModal,
    ModalBankAccountRemove,
    Multiselect,
    ModalBankAccountBankList,
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    clientId() {
      return this.$store.getters.getProfile.client_id;
    },
  },
  data() {
    return {
      bankId: null,
      fileId: null,
      country: "",
      submit: false,
      loading: false,
      update: false,
      nationalForm: false,
      internationalForm: false,
      submitInternational: false,
      editSelectedInternational: false,
      disabledBankFilds: true,
      interAccess: false,
      bankStatement: null,
      file: null,
      bank_number: null,
      bank_name: null,
      agency: null,
      agency_dv: null,
      account: null,
      account_dv: null,
      modality: null,
      type: null,
      name: null,
      bank_account_id: -1,
      selected_currencies: [],
      coins: [],
      bankAccounts: [],
      bankInternationalAccounts: [],
      accountTypes: [{ value: null, text: "Tipo de conta" }],
      accountModality: [
        { value: null, text: "Modalidade" },
        { value: "CURRENT", text: "Conta Corrente" },
        { value: "SAVINGS", text: "Conta Poupança" },
      ],
      internationalData: {
        bank_account_id: null,
        holder_name: "",
        type_international: null,
        iban: "",
        account_number: "",
        bic_swift: "",
        selected_currencies: [],
      },
      status_validation: {
        status: "",
        observation: "",
      },
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
    };
  },

  created() {
    const userInterIds = process.env.USER_INTER.slice(1, -1)
      .split(",")
      .map((userId) => parseInt(userId));

    this.interAccess = userInterIds.includes(this.$store.getters.getProfile.id);
  },
  props: {
    accountApproved: {
      type: Boolean,
    },
  },
  methods: {
    makeForm() {
      let currencies = [];

      this.selected_currencies.forEach((sc) => currencies.push(sc.id));

      const data = {
        bank_number: this.bank_number,
        bank_name: this.bank_name,
        agency: this.agency,
        agency_dv: this.agency_dv,
        account_dv: this.account_dv,
        account: this.account,
        type: this.modality,
        account_type: this.type,
        name: this.name,
        user_id: this.userId,
        international_account_type: null,
        bic_swift: null,
        currencies: (currencies = this.interAccess ? currencies : [1]),
        country: "BR",
      };
      console.log(data);
      return data;
    },

    updateBankAccounts(item) {
      this.bankId = item.id;
      this.fileId = item.last_document.id;
      this.bank_number = item.bank_number;
      this.bank_name = item.bank_name;
      this.agency = item.agency;
      this.agency_dv = item.agency_dv;
      this.account = item.account;
      this.account_dv = item.account_dv;
      this.type = item.account_type;
      this.modality = item.type;
      this.name = item.name;
      this.bank_account_id = item.bank_account_id;
      this.country = item.country;
      this.update = true;
    },

    checkForm() {
      !this.loading &&
        (this.status_validation.status == "APPROVED" ||
          (this.user.company
            ? checkStatus(this.user.company.documents, "APPROVED").length > 0
            : false));
    },

    getClassNames(status) {
      return [
        status === 0 ? "disapproved" : "",
        status === 1 ? "approved" : "",
        status === 2 ? "waiting" : "",
      ];
    },

    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },

    initVerification() {
      this.$bvModal.show("identity-validation");
    },

    selectBank(item) {
      this.bank_number = item.code;
      this.bank_name = item.name;
    },

    openConfirmRemove(id) {
      this.bank_account_id = id;
      this.$bvModal.show("bank-account-remove");
    },

    resetFields() {
      this.bank_number = null;
      this.bank_name = null;
      this.agency = null;
      this.agency_dv = null;
      this.account = null;
      this.account_dv = null;
      this.type = null;
      this.modality = null;
      this.name = null;
      this.bank_account_id = -1;
      this.update = false;
      this.selected_currencies = [];
      this.nationalForm = false;
      this.internationalForm = false;
      this.internationalData = {
        holder_name: "",
        type_international: null,
        iban: "",
        account_number: "",
        bic_swift: "",
        selected_currencies: [],
      };
      this.country = "";
      this.file = null;
    },

    async submitForm() {
      try {

        
        // Habilita os campos do banco para que possam ser validados
        this.disabledBankFilds = false;

        // Chamada de envio de formulário assíncrona
        const isValid = await this.$validator.validateAll();

        // Currencies

        if (isValid) {
          this.submit = true;

          this.loading = true;

          const form = this.makeForm();

          if (this.update) {
            await bankAccountService.update(form, this.bankId);
            const uploadFile = await this.handleUploadFile(this.bankId);
            return uploadFile;
          } else {
            const { id } = await bankAccountService.create(form);
            const uploadFile = await this.handleUploadFile(id);
            return uploadFile;
          }
        } else {
          this.$bvToast.toast("Valide os campos obrigatórios", {
            title: "Conta bancária",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.disabledBankFilds = true;
        }
      } catch (error) {
        this.submit = false;
        this.loading = false;
      }
    },

    async handleUploadFile(id) {
      const fileArray = [];

      fileArray.push(this.file);

      /* FormData */
      const form = new FormData();

      for (var i = 0; i < fileArray.length; i++) {
        form.append("document", fileArray[i]);
      }
      /* Testando envio de form com ID fixo */
      await bankAccountService
        .create(form, `/${id}/upload`)
        .then(() => {
          this.submit = false;
          this.loading = false;

          this.$bvToast.toast(
            this.$t("seller.statements.modals.bank_account.text_815"),
            {
              title: this.$t("seller.statements.modals.bank_account.text_2722"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          this.$emit("close");

          this.$bvModal.hide("bank-account");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
          this.submitInternational = false;
          this.nationalForm = false;
          this.internationalForm = false;
          this.resetFields();
          this.fetchBankAccount();
          this.goToTop();
          this.$validator.reset();
        });
    },

    async handleFormSubmit(form) {
      await bankAccountService.create(form);
    },

    fetchBankAccount() {
      this.loading = true;
      this.getStatusValidation();
      let bankAccounts = [];
      let bankInternationalAccounts = [];

      bankAccountService
        .search()
        .then((response) => {
          response.forEach((bankIntAcc) => {
            if (bankIntAcc.international_account_type != null)
              bankInternationalAccounts.push(bankIntAcc);
          });
          response.forEach((bankAcc) => {
            if (bankAcc.bic_swift == null && bankAcc.account_dv != null)
              bankAccounts.push(bankAcc);
          });
          bankAccounts = bankAccounts.reverse();
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.bankInternationalAccounts = bankInternationalAccounts;
          this.bankAccounts = bankAccounts;
          this.loading = false;
        });
    },

    goToTop() {
      const el = document.querySelector("#backAccountTop");

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },

    getStatusValidation() {
      this.loading = true;
      let data = { id: this.userId };

      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
          if (
            !this.accountTypes.some((a) => a.text === "Conta Pessoal (CPF)")
          ) {
            if (this.status_validation.status == "APPROVED") {
              var accountType = this.accountTypes;
              var result = accountType.filter((x) => x.value === "PERSONAL");
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: "Conta Pessoal (CPF)",
                });
              }
            } else {
              var accountType = this.accountTypes;
              var result = accountType.filter((x) => x.value === "PERSONAL");
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: "Conta Pessoal (CPF)",
                  disabled: true,
                });
              }
            }
          }
        })
        .catch((err) => console.error(err))
        .finally(() => (this.loading = false));
    },

    /* Get currencies from back api where is base currency and set "this.coins" in order: BRL > USD > EUR > GBP */
    fetchCurrencyCurrencies() {
      serviceCurrencyCurrencies
        .search({ is_base_currency: 1 })
        .then((response) => {
          this.coins = response.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          if (this.coins[1].currency === "USD")
            this.coins[1].currency_symbol = "$";
        });
    },
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch("userRequest")
      .then((response) => {
        this.user = response;
        this.fetchBankAccount();
        this.fetchCurrencyCurrencies();

        if (
          this.user.company &&
          this.checkStatus(this.user.company.documents, "APPROVED").length
        ) {
          var accountType = this.accountTypes;
          var result = accountType.filter((x) => x.value === "PERSONAL");
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: "Conta Empresarial (CNPJ)",
            });
          }
        } else {
          var accountType = this.accountTypes;
          var result = accountType.filter((x) => x.value === "PERSONAL");
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: "Conta Empresarial (CNPJ)",
              disabled: true,
            });
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
