<template>
  <b-row>
    <b-col cols="12">
      <!-- Lista as contas bancárias -->
      <b-row class="account-table">
        <b-col cols="12" class="d-none d-md-block">
          <div v-if="hasAccountApproved" class="account-table__action">
            <BaseButton
              variant="outline-info"
              @click="() => this.$router.push('/extrato')"
              class="btn-sacar"
            >
              {{ $t("views.seller.statements.withdraw_money") }}
            </BaseButton>
          </div>
        </b-col>
        <b-col cols="12" md="12">
          <h5 class="account-table__title">
            {{ $t("seller.my_account.bank_account.registered_accounts") }}
          </h5>

          <template v-if="!loading && bankAccounts.length > 0">
            <b-row class="account-table__header d-none d-md-flex">
              <b-col cols="3" md="4" class="account-table__header--item">
                {{ $t("seller.my_account.bank_account.bank") }}
              </b-col>
              <b-col cols="2" md="2" class="account-table__header--item">
                {{ $t("seller.my_account.bank_account.agency") }}
              </b-col>
              <b-col cols="2" md="2" class="account-table__header--item">
                {{ $t("seller.my_account.bank_account.account") }}
              </b-col>
              <b-col cols="3" md="2" class="account-table__header--item">
                {{ $t("seller.my_account.bank_account.status") }}
              </b-col>
              <b-col cols="2" md="1" class="account-table__header--item">
              </b-col>
            </b-row>

            <b-row
              class="account-table__listing"
              v-for="item in bankAccounts"
              :key="item.id"
              data-anima="top"
            >
              <b-col cols="12" md="4" class="account-table__listing--item">
                <div class="account-table__listing--item__desktop">
                  <div
                    class="d-md-none d-inline account-table__listing--item__desktop--item"
                  >
                    {{ $t("seller.statements.modals.bank_account.text_2717") }}
                  </div>
                  <div class="account-table__listing--item__desktop--name">
                    {{ item.bank_number }} -

                    {{
                      item.bank_name.length > 30
                        ? item.bank_name.substr(0, 30) + "..."
                        : item.bank_name
                    }}
                  </div>
                </div>
              </b-col>

              <b-col cols="4" md="2" class="account-table__listing--item">
                <div class="account-table__listing--item__desktop">
                  <div
                    class="d-md-none d-inline account-table__listing--item__desktop--item"
                  >
                    {{ $t("seller.statements.modals.bank_account.text_2713") }}
                  </div>
                  <div>{{ item.agency }}</div>
                </div>
              </b-col>

              <b-col cols="3" md="2" class="account-table__listing--item">
                <div class="account-table__listing--item__desktop">
                  <div
                    class="d-md-none d-inline account-table__listing--item__desktop--item"
                  >
                    {{ $t("seller.statements.modals.bank_account.text_2714") }}
                  </div>
                  <div>{{ item.account }}-{{ item.account_dv }}</div>
                </div>
              </b-col>

              <b-col
                cols="4"
                md="2"
                class="account-table__listing--item action"
              >
                <div class="account-table__listing--item__desktop">
                  <div
                    class="d-md-none d-inline account-table__listing--item__desktop--item"
                  >
                    {{ $t("seller.statements.modals.bank_account.text_2718") }}
                  </div>

                  <div
                    v-if="item.last_document"
                    :id="`badge-status-${item.id}`"
                    class="badge-status"
                    :class="getClassNames(item.last_document)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1955_2117)">
                        <path
                          class="status-imagem"
                          d="M7.67969 9.20518V6.80518M7.67969 4.40518H7.68569M13.6796 6.80518C13.6796 10.1189 10.9933 12.8052 7.67969 12.8052C4.36598 12.8052 1.67969 10.1189 1.67969 6.80518C1.67969 3.49147 4.36598 0.805176 7.67969 0.805176C10.9933 0.805176 13.6796 3.49147 13.6796 6.80518Z"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1955_2117">
                          <rect width="15" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span class="status-description">{{
                      getStatus(item.last_document)
                    }}</span>
                  </div>
                </div>
                <b-tooltip
                  v-if="item.last_document && item.last_document.pending_motive"
                  :target="`badge-status-${item.id}`"
                  :title="
                    item.last_document && item.last_document.pending_motive
                      ? item.last_document.pending_motive
                      : 'Não informado'
                  "
                  placement="topright"
                />
              </b-col>

              <b-col
                cols="6"
                md="2"
                class="account-table__listing--item text-center"
              >
                <div class="account-table__listing--item__desktop">
                  <template
                    v-if="
                      accountApproved && 
                      item.last_document &&
                      item.last_document.status === 'DISAPPROVED'
                    "
                  >
                    <div>
                      <a @click="updateForm(item)">
                        {{ $t("seller.my_account.bank_account.edit_info") }}
                      </a>
                    </div>
                  </template>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>

      <!-- Espaçamento -->
      <hr />

      <!-- Informações do suporte -->
      <b-row class="info-support">
        <b-col cols="12">
          <p>
            {{ $t("seller.my_account.bank_account.info_remove_account") }}
            <a href="https://faq.voompcreators.com.br/fale-conosco/" target="_blank"> {{ $t("seller.my_account.bank_account.support") }} </a>
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    bankAccounts: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    accountApproved: {
      type: Boolean,
    },
  },
  computed: {
    hasAccountApproved() {
      const statusArray = this.bankAccounts.flatMap((item) =>
        item.last_document ? [item.last_document.status] : []
      );
      const hasApprovedStatus = statusArray.some(
        (status) => status === "APPROVED"
      );
      return hasApprovedStatus;
    },
  },
  methods: {
    getStatus(item) {
      return item && item.status === "REQUESTED"
        ? this.$t("seller.my_account.bank_account.waiting")
        : item && item.status === "APPROVED"
        ? this.$t("seller.my_account.bank_account.approved")
        : this.$t("seller.my_account.bank_account.rejected");
    },
    getClassNames(item) {
      return item && item.status === "REQUESTED"
        ? "waiting"
        : item && item.status === "APPROVED"
        ? "approved"
        : "disapproved";
    },
    updateForm(item) {
      this.$emit("update-form", item);
      const registerAccount = document.querySelector(".register-account");
      if (registerAccount) {
        registerAccount.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss">
.account-table {
  &__title {
    @include title-style;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    &--item {
      font-weight: 600;
      height: 38px;
      /* Desktop */
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__listing {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-16);

    &--item {
      align-items: center;
      display: flex;

      /* Desktop */
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        height: 38px;
      }
      /* Desktop */
      &__desktop {
        display: flex;
        flex-direction: column;
        height: 80px;

        a:not([href]):not([class]) {
          color: var(--color-secondary);
          cursor: pointer;
          text-decoration: underline;
        }

        &--name {
          display: flex;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (min-width: 768px) {
          height: auto;
          width: 100%;
        }

        &--item {
          font-weight: 600;
          height: 38px;

          /* Desktop */
          @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.info-support {
  p {
    color: var(--color-neutral-gray-800);
    margin-bottom: var(--spacing-20);
    a {
      color: var(--color-primary);
    }
  }
}
</style>
