<template>
  <div class="container-paises" :class="{ girar: paises }">
    <div @click="paises = !paises" class="item-pais pais-selecionado">
      <img :src="require(`@/assets/locales/flag-${pais_code}.svg`)" />
      {{ paises ? $t("locales.close") : $t("locales.trocar") }}
    </div>
    <ul class="paises" data-anima="top" v-show="paises">
      <!-- <div class="container-pesquisa mt-3 mt-md-0">
        <div class="inputSearch">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            type="text"
            :placeholder="$t('locales.placeholder')"
            v-model="search"
            class="input-busca"
          />
        </div>
      </div> -->

      <div v-for="list in filteredItems" :key="list.sigla">
        <li class="item-pais" @click="changeCountry(list.sigla, list.pais)">
          <img :src="require(`@/assets/locales/flag-${list.sigla}.svg`)" />
          {{ list.sub }} | {{ list.pais }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "Locales",
  data() {
    return {
      // locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      pais: "",
      paises: false,
      pais_nome: "BR | Português",
      pais_code: "BR",
      search:'',
      lists:[
        {sigla:'BR', sub:'Brazil', pais:'Brasil'},
        {sigla:'ES', sub:'España', pais:'Spain'},
      ]
    };
  },
  computed: {
    filteredItems() {
      return !this.search.length 
        ? this.lists 
        : this.lists.filter(item => item.pais.toLowerCase().includes(this.search.toLowerCase().trim()) || item.sub.toLowerCase().includes(this.search.toLowerCase().trim()) || item.sigla.toLowerCase().includes(this.search.toLowerCase().trim()))
    }
  },
  methods: {
    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = !this.paises;
    },
    selectCountry(locale) {
      localStorage.setItem('locationadm', locale);
      this.search = '';
      if (locale === 'BR' || locale === 'pt' || !locale) {
        this.$i18n.locale = "pt";
      }else if(locale == "AR" ||
          locale == "CO" ||
          locale == "CL" ||
          locale == "EC" ||
          locale == "GT" ||
          locale == "MX" ||
          locale == "PE" ||
          locale == "UY" ||
          locale == "ES"){
          this.$i18n.locale = "es";
          let pais = this.lists.filter(item => item.sigla === locale);
          this.pais_nome = `${locale} | ${pais}`;
          this.pais_code = locale;
        }else{
        this.$i18n.locale = "en";
        this.pais_nome = "US | English";
        this.pais_code = "US";
      }
    },
  },
  created() {
    const locale = localStorage.locationadm;
    this.selectCountry(locale);
    EventBus.$on("changeLocale", (data) => {
      localStorage.setItem('locationadm', data.locale);
      if (data.locale == "BR" || data.locale == "pt") {
        this.selectCountry("BR");
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      }else if(
          data.locale == "AR" ||
          data.locale == "CO" ||
          data.locale == "CL" ||
          data.locale == "EC" ||
          data.locale == "GT" ||
          data.locale == "MX" ||
          data.locale == "PE" ||
          data.locale == "UY" ||
          data.locale == "ES"
        ){
        this.selectCountry(data.locale);
        let pais = this.lists.filter(item => item.sigla === data.locale);
        this.pais_nome = `${data.locale} | ${pais}`;
        this.pais_code = data.locale;
      } else {
        this.selectCountry("US");
        this.pais_nome = "US | English";
        this.pais_code = "US";
      }
    });
  },
};
</script>

<style scoped>
/* // paises */
.container-paises {
  position: absolute;
  right: 0;
  z-index: 9999;
  width: 180px;
}

.input-busca {
  width: max-content;
  height: 40px !important;
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
  transition: 0.5s;
  margin-bottom: 15px;
}
.input-busca::placeholder {
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  top: 27px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
    top: 43px;
  }
  .pais-selecionado {
    margin-left: 30px !important;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.paises {
  background: var(--bg-checkout);
  width: max-content;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* left: -53px; */
}

.pais-selecionado {
  margin-left: 15px;
}
.pais-selecionado::after {
  content: "";
  background: url("../assets/img/icons/arrow-fill.svg") no-repeat center center;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
}
.girar .pais-selecionado::after {
  transform: rotate(180deg);
  transition: all .3s;
}
.item-pais {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  padding: 7px 10px;
  /* font-weight: 600; */
  padding-right: 0;
  transition: 0.3s;
  color: var(--text-color);
  position: relative;
  margin-bottom: 5px;
}
.item-pais::before {
  content: "";
  width: 5px;
  height: 5px;
  background: transparent;
  position: absolute;
  /* left: -5px; */
  border-radius: 50%;
}
.item-pais + .item-pais {
  margin-top: 10px;
}
.item-pais:hover {
  color: var(--main-color);
}
.item-pais:hover::before {
  background: var(--main-color);
}
.item-pais img {
  width: 20px;
  height: 20px;
}
</style>